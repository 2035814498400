import React, { FormEvent, useState } from 'react';
import PlayerSelector from '../components/PlayerSelector';
import Player from '../game/player';
import { RoleActivityProps } from '../game/roles/model';

export default function RolePlayerSelector(
  { game, roleId, reRender }: RoleActivityProps,
): React.ReactElement | null {
  const [selected, setSelected] = useState<number[]>([]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const role = game.getRoleById(roleId);
    game.players.filter((player: Player) => selected.includes(player.getId()))
      .forEach((player: Player) => player.updateRole(role));

    role.setPlayersKnown(true);
    reRender();
  };

  return (
    <form onSubmit={onSubmit}>
      <h3>Valitse pelaajat tässä roolissa</h3>
      <PlayerSelector
        multiple
        includeNone={false}
        game={game}
        value={selected}
        onChange={setSelected}
        filterPlayers={null}
      />

      <div className="container-block">
        <button type="submit" className="button">
          OK
        </button>
      </div>
    </form>
  );
}
