import React, { FormEvent, useState } from 'react';
import Game from '../game/game';
import Player from '../game/player';
import Role from '../game/roles/model';
import ROLES from '../game/roles/roleMapping';

type SetupProps = {
  // eslint-disable-next-line no-unused-vars
  setGame: (game: Game) => void;
};

export default function Setup({ setGame }: SetupProps): React.ReactElement {
  const [error, setError] = useState<string | null>(null);
  const [playerFieldData, setPlayerFieldData] = useState<string>('');
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const resetError = () => {
    if (error) {
      setError(null);
    }
  };

  const setPlayersData = (value: string) => {
    setPlayerFieldData(value);
    resetError();
  };

  const toggleRole = (roleId: string) => {
    if (selectedRoles.includes(roleId)) {
      setSelectedRoles(selectedRoles.filter((it: string) => it !== roleId));
    } else {
      setSelectedRoles([...selectedRoles, roleId]);
    }

    resetError();
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const game = new Game();
    const newErrors = [];

    game.roles = ROLES.filter((role: Role) => selectedRoles.includes(role.getId()));
    if (game.roles.length === 0) {
      newErrors.push('Yhtäkään roolia ei ole valittu.');
    }
    game.roles.push(...ROLES.filter((role: Role) => role.isMandatory()));

    const villagerRole = game.getRoleById('VILLAGER');
    villagerRole.setPlayersKnown(true);

    let playerId = 1;
    playerFieldData.split('\n')
      .map((name: string) => name.trim())
      .filter((name: string) => name !== '')
      // TODO: filter for unique values
      .forEach((name: string) => {
        game.players.push(new Player(playerId, name, villagerRole));
        playerId += 1;
      });
    if (game.players.length < 3) {
      newErrors.push('Peliin tarvitaan ainakin kolme pelaajaa.');
    }

    if (newErrors.length > 0) {
      setError(newErrors.join(' '));
      return;
    }

    setGame(game);
  };

  return (
    <form onSubmit={onSubmit}>
      {error && (
      <div className="container-block">
        <div className="error">
          Virhe:
          {error}
        </div>
      </div>
      )}

      <div className="container-block">
        <label htmlFor="playersTextField">
          <h2>Pelaajat</h2>
          <textarea
            id="playersTextField"
            onChange={(e) => setPlayersData(e.target.value)}
            value={playerFieldData}
            rows={10}
          />
        </label>
      </div>

      <div className="container-block">
        <h2>Käytössä olevat roolit</h2>

        {ROLES.filter((role: Role) => !role.isMandatory()).map((role: Role) => (
          <div key={`role-selector-${role.getId()}`}>
            <label htmlFor={`role-selector-${role.getId()}`}>
              <input
                type="checkbox"
                id={`role-selector-${role.getId()}`}
                checked={selectedRoles.includes(role.getId())}
                onChange={() => toggleRole(role.getId())}
              />

              <span>{role.getName()}</span>
            </label>
          </div>
        ))}
      </div>

      <div className="container-block">
        <button type="submit" className="button">
          Aloita peli
        </button>
      </div>
    </form>
  );
}
