import React, { useEffect, useMemo, useState } from 'react';
import Game from '../game/game';
import Role from '../game/roles/model';

type NightProps = {
  game: Game;
  currentRole: Role;
  next: () => void;
};

export default function Night({ game, currentRole, next }: NightProps): React.ReactElement {
  const [reRenderCounter, setReRenderCounter] = useState<number>(0);
  const RoleActivity = useMemo(
    () => currentRole.getNightActivity(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRole, reRenderCounter],
  );
  const rendededRoleActivity = useMemo(
    () => RoleActivity && (
      <RoleActivity
        game={game}
        next={next}
        roleId={currentRole.getId()}
        reRender={() => setReRenderCounter(reRenderCounter + 1)}
        reRenderCounter={reRenderCounter}
      />
    ),
    [RoleActivity, game, next, currentRole, reRenderCounter],
  );

  useEffect(() => {
    if (!rendededRoleActivity) {
      next();
    }
  }, [next, rendededRoleActivity]);

  if (!rendededRoleActivity) {
    return <div />;
  }

  return (
    <div>
      <div className="container-block">
        <h2>
          Yö:
          {' '}
          {currentRole.getName()}
        </h2>
      </div>

      {rendededRoleActivity}
    </div>
  );
}
