import RolePlayerSelector from '../../views/RolePlayerSelector';
import SinglePlayerActivitySelector from '../../views/SinglePlayerActivitySelector';
import Game from '../game';
import Player from '../player';
import Role, { BAD_YES } from './model';

export default class Mafioso extends Role {
  private killedPerson: number | null = null;

  getId(): string {
    return 'MAFIOSO';
  }

  getName(): string {
    return 'Mafioso';
  }

  getPriority(): number {
    return 100;
  }

  isBad(): string {
    return BAD_YES;
  }

  getNightActivity() {
    if (!this.arePlayersKnown()) {
      return RolePlayerSelector;
    }

    return SinglePlayerActivitySelector(
      'Kenet tapettiin?',
      (selected: number | null) => {
        this.killedPerson = selected;
        return false;
      },
    );
  }

  onMorning(game: Game): string[] {
    if (this.killedPerson === null) {
      return [];
    }

    const slainPerson = game.getPlayerById(this.killedPerson);

    if (!slainPerson) {
      return [];
    }

    if (!game.killPlayer(slainPerson)) {
      return [];
    }

    return [
      `Mafia tappoi pelaajan ${slainPerson.getName()}!`,
    ];
  }

  // eslint-disable-next-line no-unused-vars
  onAfterMorning(game: Game): void {
    this.killedPerson = null;
  }

  checkWin(game: Game): boolean {
    return game.players
      .map((player: Player) => ((player.getRole().getId() === this.getId()) ? 1 : -1))
      .reduce((sum: number, next: number) => sum + next, 0) >= 0;
  }
}
