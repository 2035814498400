import Role from './roles/model';

export default class Player {
  private id: number;

  private name: string;

  private role: Role;

  constructor(id: number, name: string, role: Role) {
    this.id = id;
    this.name = name;
    this.role = role;
  }

  getId(): number {
    return this.id;
  }

  getName(): string {
    return this.name;
  }

  updateRole(role: Role) {
    this.role = role;
  }

  getRole(): Role {
    return this.role;
  }
}
