import React from 'react';
import Game from '../game';
import Player from '../player';

export type RoleActivityProps = {
  game: Game;
  roleId: string;
  next: () => void;

  reRender: () => void;
  reRenderCounter: number;
};

export const BAD_NO = 'hyvä';
export const BAD_YES = 'paha';

abstract class Role {
  private playersKnown: boolean = false;

  abstract getId(): string;

  abstract getName(): string;

  abstract getPriority(): number;

  isMandatory(): boolean {
    return false;
  }

  isBad(): string {
    return BAD_NO;
  }

  // eslint-disable-next-line no-unused-vars
  getNightActivity(): ((props: RoleActivityProps) => React.ReactElement | null) | null {
    return null;
  }

  arePlayersKnown(): boolean {
    return this.playersKnown;
  }

  setPlayersKnown(known: boolean) {
    this.playersKnown = known;
  }

  // eslint-disable-next-line no-unused-vars
  onMorning(game: Game): string[] {
    return [];
  }

  // eslint-disable-next-line no-unused-vars
  onAfterMorning(game: Game): void {}

  // eslint-disable-next-line no-unused-vars
  checkWin(game: Game): boolean {
    return false;
  }

  // eslint-disable-next-line no-unused-vars
  preventsDeath(game: Game, player: Player): boolean {
    return false;
  }
}

export default Role;
