import Player from './player';
import Role from './roles/model';

/**
 * Represents the entire game.
 */
export default class Game {
  public players: Player[] = [];

  public roles: Role[] = [];

  getRoleById(id: string): Role {
    const roles = this.roles.filter((role: Role) => role.getId() === id);
    if (roles.length !== 1) {
      throw new Error(`Role ${id} not found or not in this game`);
    }

    return roles[0];
  }

  getPlayerById(id: number): Player | null {
    const players = this.players.filter((player: Player) => player.getId() === id);
    if (players.length !== 1) {
      return null;
    }

    return players[0];
  }

  killPlayer(player: Player): boolean {
    if (this.roles.filter((role: Role) => role.preventsDeath(this, player)).length > 0) {
      return false;
    }

    this.players = this.players.filter((it: Player) => player.getId() !== it.getId());
    return true;
  }
}
