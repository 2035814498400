import React from 'react';
import Game from '../game/game';
import { RoleActivityProps } from '../game/roles/model';

export default function SimpleMessage(
  // eslint-disable-next-line no-unused-vars
  getMessage: (game: Game) => string,
  hasMoreStuff: boolean = true,
) {
  return function InnerSimpleMessage(
    { game, next, reRender }: RoleActivityProps,
  ): React.ReactElement | null {
    const onClick = () => {
      if (hasMoreStuff) {
        reRender();
      } else {
        next();
      }
    };

    return (
      <div>
        <div>
          {getMessage(game)}
        </div>

        <div className="container-block">
          <button type="button" className="button" onClick={onClick}>
            OK
          </button>
        </div>
      </div>
    );
  };
}
