import React, { FormEvent, useState } from 'react';
import PlayerSelector from '../components/PlayerSelector';
import Player from '../game/player';
import { RoleActivityProps } from '../game/roles/model';

export default function SinglePlayerActivitySelector(
  prompt: string,
  // eslint-disable-next-line no-unused-vars
  select: (selected: number | null) => boolean,

  // eslint-disable-next-line no-unused-vars
  filterPlayers: ((player: Player) => boolean) | null = null,
) {
  return function InnerSinglePlayerActivitySelector(
    { game, next, reRender }: RoleActivityProps,
  ): React.ReactElement | null {
    const [selected, setSelected] = useState<number[]>([]);

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const hasMoreStuff = select(selected.length === 1 ? selected[0] : null);

      if (hasMoreStuff) {
        reRender();
      } else {
        next();
      }
    };

    return (
      <form onSubmit={onSubmit}>
        <h3>{prompt}</h3>
        <PlayerSelector
          multiple={false}
          includeNone
          game={game}
          value={selected}
          onChange={setSelected}
          filterPlayers={filterPlayers}
        />

        <div className="container-block">
          <button type="submit" className="button">
            OK
          </button>
        </div>
      </form>
    );
  };
}
