import Doctor from './doctor';
import Healer from './healer';
import Mafioso from './mafioso';
import Role from './model';
import Police from './police';
import Villager from './villager';

const ROLES: Role[] = [
  new Doctor(),
  new Healer(),
  new Mafioso(),
  new Police(),
  new Villager(),
];

export default ROLES;
