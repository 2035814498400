import RolePlayerSelector from '../../views/RolePlayerSelector';
import SinglePlayerActivitySelector from '../../views/SinglePlayerActivitySelector';
import Game from '../game';
import Player from '../player';
import Role from './model';

export default class Doctor extends Role {
  private savedNow: number | null = null;

  private savedInThisGame: number[] = [];

  getId(): string {
    return 'DOCTOR';
  }

  getName(): string {
    return 'Lääkäri';
  }

  getPriority(): number {
    return 50;
  }

  filterPlayers(player: Player): boolean {
    if (this.savedInThisGame.includes(player.getId())) {
      return false;
    }

    return true;
  }

  getNightActivity() {
    if (!this.arePlayersKnown()) {
      return RolePlayerSelector;
    }

    return SinglePlayerActivitySelector(
      'Kuka pelastetaan?',
      (selected: number | null) => {
        this.savedNow = selected;
        return false;
      },
      this.filterPlayers,
    );
  }

  preventsDeath(game: Game, player: Player): boolean {
    if (player.getId() === this.savedNow) {
      return true;
    }

    return false;
  }

  // eslint-disable-next-line no-unused-vars
  onAfterMorning(game: Game): void {
    if (this.savedNow) {
      this.savedInThisGame.push(this.savedNow);
    }

    this.savedNow = null;
  }
}
