import Role from './model';
import SinglePlayerActivitySelector from '../../views/SinglePlayerActivitySelector';
import Game from '../game';
import SimpleMessage from '../../views/SimpleMessage';
import RolePlayerSelector from '../../views/RolePlayerSelector';

export default class Police extends Role {
  private selected: number | null = null;

  getId(): string {
    return 'POLICE';
  }

  getName(): string {
    return 'Poliisi';
  }

  getPriority(): number {
    return 20;
  }

  getNightActivity() {
    if (!this.arePlayersKnown()) {
      return RolePlayerSelector;
    }

    if (this.selected) {
      return SimpleMessage(
        (game: Game) => {
          const player = game.getPlayerById(this.selected!!);
          return `${player?.getName()} on ${player?.getRole().isBad()}`;
        },
        false,
      );
    }

    return SinglePlayerActivitySelector(
      'Kuka tarkistetaan?',
      (selected: number | null) => {
        if (!selected) {
          return false;
        }

        this.selected = selected;
        return true;
      },
    );
  }

  // eslint-disable-next-line no-unused-vars
  onMorning(game: Game): string[] {
    this.selected = null;
    return [];
  }
}
