import Game from '../game';
import Player from '../player';
import Role, { BAD_NO } from './model';

export default class Villager extends Role {
  getId(): string {
    return 'VILLAGER';
  }

  getName(): string {
    return 'Kyläläinen';
  }

  getPriority(): number {
    return 0;
  }

  isMandatory(): boolean {
    return true;
  }

  checkWin(game: Game): boolean {
    return game.players.filter(
      (player: Player) => player.getRole().isBad() !== BAD_NO,
    ).length === 0;
  }
}
