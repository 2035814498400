import React, { useState } from 'react';
import PlayerSelector from '../components/PlayerSelector';
import Game from '../game/game';

type DayProps = {
  startNight: () => void;
  dayMessages: string[];
  game: Game;
};

export default function Day({ startNight, dayMessages, game }: DayProps): React.ReactElement {
  const [voteStarted, setVoteStarted] = useState<boolean>(false);
  const [voteSelected, setVoteSelected] = useState<number[]>([]);

  if (voteStarted) {
    const onClick = () => {
      if (voteSelected.length === 1) {
        const selected = game.getPlayerById(voteSelected[0]);
        if (selected) {
          game.killPlayer(selected);
        }
      }

      startNight();
    };

    return (
      <div>
        <h2>Päivän äänestys</h2>

        <PlayerSelector
          multiple={false}
          includeNone
          game={game}
          value={voteSelected}
          onChange={setVoteSelected}
          filterPlayers={null}
        />

        <div className="container-block">
          <button type="button" className="button" onClick={onClick}>Aloita yö</button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2>Päivä</h2>

      {dayMessages.map((message: string) => (
        <div key={`dayMessage-${message}`} className="container-block">
          {message}
        </div>
      ))}

      <div className="container-block">
        <button type="button" className="button" onClick={() => setVoteStarted(true)}>Aloita yö</button>
      </div>
    </div>
  );
}
