import React from 'react';
import Game from '../game/game';
import Player from '../game/player';

type PlayerSelectorProps = {
  game: Game;
  multiple: boolean;
  includeNone: boolean;

  value: number[];
  // eslint-disable-next-line no-unused-vars
  onChange: (newValue: number[]) => void;

  // eslint-disable-next-line no-unused-vars
  filterPlayers: ((player: Player) => boolean) | null,
};

export default function PlayerSelector(
  {
    game, multiple, includeNone, value, onChange, filterPlayers = null,
  }: PlayerSelectorProps,
): React.ReactElement | null {
  const togglePlayer = (playerId: number) => {
    if (!multiple) {
      onChange([playerId]);
    } else if (value.includes(playerId)) {
      onChange(value.filter((it: number) => it !== playerId));
    } else {
      onChange([...value, playerId]);
    }
  };

  const setEmpty = () => {
    onChange([]);
  };

  const realFilter = (player: Player) => (filterPlayers ? filterPlayers(player) : true);

  return (
    <div>
      {game.players.filter(realFilter).map((player: Player) => (
        <div key={`player-selector-${player.getId()}`}>
          <label htmlFor={`player-selector-${player.getId()}`}>
            <input
              type={multiple ? 'checkbox' : 'radio'}
              id={`player-selector-${player.getId()}`}
              checked={value.includes(player.getId())}
              onChange={() => togglePlayer(player.getId())}
            />

            <span>{player.getName()}</span>
          </label>
        </div>
      ))}

      {!multiple && includeNone && (
        <div>
          <label htmlFor="player-selector-none">
            <input
              type="radio"
              id="player-selector-none"
              checked={value.length === 0}
              onChange={() => setEmpty()}
            />

            Ei kukaan
          </label>
        </div>
      )}
    </div>
  );
}
