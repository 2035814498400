import React, { useState } from 'react';
import './App.css';
import Game from './game/game';
import Role from './game/roles/model';
import Day from './views/Day';
import Night from './views/Night';
import Setup from './views/Setup';
import Winners from './views/Winners';

function App() {
  const [game, setGame] = useState<Game | null>(null);
  const [nightPendingRoles, setNightPendingRoles] = useState<string[]>([]);
  const [dayMessages, setDayMessages] = useState<string[]>([]);
  const [winners, setWinners] = useState<string[]>([]);

  if (!game) {
    const startGame = (newGame: Game) => {
      setGame(newGame);
      setNightPendingRoles(newGame.roles.map((role: Role) => role.getId()));
      setDayMessages([]);
      setWinners([]);
    };

    return <Setup setGame={startGame} />;
  }

  if (winners.length > 0) {
    const reset = () => setGame(null);
    return <Winners winners={winners} reset={reset} />;
  }

  if (nightPendingRoles.length > 0) {
    const nightCurrentRole = nightPendingRoles.map((id: string) => game.getRoleById(id)).sort(
      (a: Role, b: Role) => (b.getPriority() - a.getPriority()),
    )[0];

    const nightNextRole = () => {
      const newRoles = nightPendingRoles.filter((it: string) => it !== nightCurrentRole.getId());
      setNightPendingRoles(newRoles);

      if (newRoles.length === 0) {
        // day starts now
        setDayMessages(
          game.roles.flatMap((role: Role) => role.onMorning(game)),
        );

        setWinners(
          game.roles
            .filter((role: Role) => role.checkWin(game))
            .map((role: Role) => role.getName()),
        );

        game.roles.forEach((role: Role) => role.onAfterMorning(game));
      }
    };

    return (
      <Night
        game={game}
        currentRole={nightCurrentRole}
        next={nightNextRole}
      />
    );
  }

  const startNight = () => {
    setWinners(
      game.roles
        .filter((role: Role) => role.checkWin(game))
        .map((role: Role) => role.getName()),
    );

    setNightPendingRoles(game.roles.map((role: Role) => role.getId()));
  };

  return (
    <Day
      startNight={startNight}
      dayMessages={dayMessages}
      game={game}
    />
  );
}

export default App;
