import Player from '../player';
import Doctor from './doctor';

export default class Healer extends Doctor {
  getId(): string {
    return 'HEALER';
  }

  getName(): string {
    return 'Parantaja';
  }

  // eslint-disable-next-line no-unused-vars
  filterPlayers(player: Player): boolean {
    return true;
  }
}
