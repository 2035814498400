import React from 'react';

type DayProps = {
  reset: () => void;
  winners: string[];
};

export default function Winners({ reset, winners }: DayProps): React.ReactElement {
  return (
    <div>
      <h2>Voitto</h2>

      <div className="container-block">
        {winners.join(', ')}
        {' '}
        voitti!
      </div>

      <div className="container-block">
        <button type="button" className="button" onClick={reset}>Uusi peli</button>
      </div>
    </div>
  );
}
